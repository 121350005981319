import React from "react";
import useAuth from "../hooks/useAuth";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { addRedirectUrlToPath, getRedirectUrl, setRedirectUrl } from "src/utils/pathUtils";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  
  if (!isAuthenticated) {
    console.log(location);
    setRedirectUrl(location.pathname);
    return <Redirect to={addRedirectUrlToPath("/login", getRedirectUrl())} />;
  }

  if(user.isTempPassword){
    return <Redirect to={addRedirectUrlToPath("/app/changePassword", getRedirectUrl())} />;
  }

  if(user.twoFactorEnabled && !user.isCodeVerified){
    return <Redirect to={addRedirectUrlToPath("/app/twoFactorVerification", getRedirectUrl())} />;
  }

  let redirectUrl = getRedirectUrl();
  if(redirectUrl
    && redirectUrl !== "")
  {
    setRedirectUrl("");
    return <Redirect to={redirectUrl} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
